import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
    listaUsuarios: [],
    obraId: '',
    obraAtual: {},
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
    setObra(state, data) {
      state.obraId = data
    },
    setObraAtual(state, data) {
      state.obraAtual = data
    },
  },
  actions: {
    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve) => {
        axios
          .get('/obras', { params: queryParams })
          .then((response) => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch((error) => console.log(error))
      })
    },

    fetchRegistrosObra({ commit }, queryParams) {
      commit('alteraLoading', true)

      return new Promise((resolve) => {
        axios
          .get(`/obras/${queryParams.idObra}/registros`, {
            params: queryParams,
          })
          .then((response) => {
            resolve(response.data)
            commit('alteraLoading', false)
            commit('setObra', queryParams.idObra)
          })
          .catch((error) => console.log(error))
      })
    },

    fetchUsuarios({ commit }, queryParams) {
      return new Promise((resolve) => {
        axios
          .get('/users', { queryParams })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => console.log(error))
      })
    },

    fetchObra({ commit }, idObra) {
      return new Promise((resolve, reject) => {
        axios
          .get(`obras/${idObra}`)
          .then((response) => {
            commit('setObraAtual', response.data)
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },

    store(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/obras', itemData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    update(ctx, itemData) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        formData.append('titulo', itemData.titulo)
        formData.append('cliente_id', itemData.cliente.uuid)
        if (itemData.assessoria) {
          formData.append(
            'assessoria_id',
            itemData.assessoria.id || itemData.assessoria.uuid,
          )
        }

        if (itemData.nome_relatorio) {
          formData.append('nome_relatorio', itemData.nome_relatorio)
        }

        formData.append('data_inicio', itemData.data_inicio)

        Object.keys(itemData.localizacoes).forEach((chave) => {
          formData.append('localizacoes[]', itemData.localizacoes[chave].nome)
        })

        Object.keys(itemData.especificacoes).forEach((chave) => {
          formData.append('especificacoes[]', itemData.especificacoes[chave].id)
        })

        Object.keys(itemData.users).forEach((chave) => {
          formData.append(
            `users[${chave}]`,
            itemData.users[chave].id || itemData.users[chave].uuid,
          )
        })

        Object.keys(itemData.times).forEach((chave) => {
          formData.append(
            `times[${chave}]`,
            itemData.times[chave].id || itemData.times[chave].uuid,
          )
        })

        Object.keys(itemData.endereco).forEach((chave) => {
          if (itemData.endereco[chave]) {
            formData.append(`endereco[${chave}]`, itemData.endereco[chave])
          }
        })

        if (itemData.imagemSelected) {
          formData.append('imagem', itemData.imagemSelected)
        }

        formData.append('_method', 'PUT')

        axios
          .post(`obras/${itemData.uuid}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/obras/${id}`)
          .then(() => resolve())
          .catch((error) => reject(error))
      })
    },
  },
}
